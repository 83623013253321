import React from "react";
import { HexColorPicker } from "react-colorful";

export default function CustomColorPicker(props) {
  return (
    <div className="color-picker-responsive-wrapper">
      <HexColorPicker {...props} />
    </div>
  );
}
